/** @jsx jsx */
import { jsx, Box, Card, Image, Grid, Text, Flex } from "theme-ui";
import { Link } from "gatsby";
import PropTypes from "prop-types";

const MenuItems = ({ items }) => (
  <Grid width={220} repeat="fill" p="2">
    {items.map(
      ({ key, label, subLabel, to, icon, allow = true, FreeFlowContent }) =>
        allow &&
        (!FreeFlowContent ? (
          <Card
            key={key}
            sx={{
              boxShadow: "0px 3px 6px #00000029",
              opacity: 1,
              cursor: "pointer",
              borderRadius: "2px",
              border: "2px solid ",
              borderColor: "background",
              "&:hover": {
                border: "2px solid",
                borderColor: "primary",
              },
              backgroundColor: "background",
            }}
          >
            <Link to={to} sx={{ textDecoration: "none" }}>
              <Flex sx={{ gap: 3, p: 1 }}>
                <Image
                  src={icon}
                  alt={label}
                  sx={{ height: "100px", width: "100px" }}
                />

                <Box sx={{ mt: 2, flex: "1 1 auto" }}>
                  <Text variant="title">{label}</Text>
                  <hr sx={{ color: "muted" }} />
                  <Text variant="subtitle"> {subLabel} </Text>
                </Box>
              </Flex>
            </Link>
          </Card>
        ) : (
          <FreeFlowContent />
        ))
    )}
  </Grid>
);

MenuItems.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      subLabel: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
      icon: PropTypes.node,
      allow: PropTypes.bool,
      key: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default MenuItems;
