/** @jsx jsx */
import HFNMeditate from "../../assets/hfn-meditate.png";

const daajiMenuItems = [
  {
    label: "Preceptor Special Sittings 2022",
    subLabel: "Observations shared by Preceptors >>",
    to: "/daaji/preceptor-sittings-notes",
    icon: HFNMeditate,
    key: "22",
    isTrainer: true,
  },
];

export default daajiMenuItems;
